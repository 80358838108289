
import {computed, defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {isValidUsername} from '@/utils/validate';
import {useI18n} from 'vue-i18n';
import {useRoute, useRouter} from 'vue-router';
import logo from '@/assets/logo.svg';
import {ElForm, ElMessage} from 'element-plus';
import useRequest from '@/services/request';

const {
  post,
} = useRequest();

export default defineComponent({
  name: 'Login',
  setup() {
    const route = useRoute();

    const router = useRouter();

    const loading = ref<boolean>(false);

    const isSignup = computed(() => route.path === '/signup');

    const loginForm = ref<LoginForm>({
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
    });

    const loginFormRef = ref<typeof ElForm>();

    const validateUsername = (rule: any, value: any, callback: any) => {
      if (!isValidUsername(value)) {
        callback(new Error('Please enter the correct username'));
      } else {
        callback();
      }
    };

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value.length < 5) {
        callback(new Error('Password length should be no shorter than 5'));
      } else {
        callback();
      }
    };

    const validateConfirmPass = (rule: any, value: any, callback: any) => {
      if (!isSignup.value) return callback();
      if (value !== loginForm.value.password) {
        callback(new Error('Two passwords must be the same'));
      } else {
        callback();
      }
    };

    const loginRules: LoginRules = {
      username: [{required: true, trigger: 'blur', validator: validateUsername}],
      password: [{required: true, trigger: 'blur', validator: validatePass}],
      confirmPassword: [{required: true, trigger: 'blur', validator: validateConfirmPass}]
    };

    const isShowMobileWarning = ref<boolean>(false);

    const allowRegister = ref<boolean>(false);

    const lang = computed<string | null>(() => localStorage.getItem('lang'));

    const setLang = (lang: string) => {
      localStorage.setItem('lang', lang);
    };

    const onLogin = async () => {
      if (!loginFormRef.value) return;
      await loginFormRef.value.validate();
      const {username, password} = loginForm.value;
      loading.value = true;
      try {
        const res = await post<LoginForm, ResponseWithData>('/login', {
          username,
          password,
        });
        if (!res.data) {
          ElMessage.error('No token returned');
          return;
        }
        localStorage.setItem('token', res.data);
        await router.push('/');
      } catch (e) {
        if (e.toString().includes('401')) {
          ElMessage.error('Unauthorized. Please check username and password.');
        } else {
          ElMessage.error(e.toString());
        }
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      if (window.innerWidth >= 1024) {
        if (!window.initCanvas) {
          import('../../assets/js/loginCanvas.js');
        } else {
          window.initCanvas();
        }
      } else {
        isShowMobileWarning.value = true;
      }
    });
    onUnmounted(() => {
      if (window.resetCanvas) {
        window.resetCanvas();
      }
    });

    return {
      loginForm,
      loginFormRef,
      loginRules,
      isShowMobileWarning,
      allowRegister,
      isSignup,
      loading,
      lang,
      logo,
      setLang,
      onLogin,
    };
  }
});
